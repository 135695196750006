<div role="alert"
     class="alert alert-info">
    Seed a training organisation.
    <br /><br />
    This process can take a long time. It MAY time out - please DON'T rerun it unless you are very sure it has completed!
</div>

<div class="d-flex flex-column mb-3">
    <label>Source organisation to seed from:</label>
    <adapt-select-organisation class="mt-1"
                               [(organisation)]="masterOrganisation"
                               [filter]="filter">
    </adapt-select-organisation>

    <label for="urlIdentifier"
           class="mt-3">URL Identifier of training organisation:</label>
    <dx-text-box id="urlIdentifier"
                 [(value)]="urlIdentifier"
                 (valueChange)="onUrlIdentifierChanged($event)"></dx-text-box>

    <label for="architectureOnly"
           class="mt-3">Copy architecture &amp; people only:</label>
    <dx-check-box id="architectureOnly"
                  [(value)]="copyArchitectureOnly"></dx-check-box>
</div>

<button adaptButtonIcon
        [disabled]="!masterOrganisation"
        [adaptBlockingClick]="seedTrainingOrgs">Seed Training Organisation</button>

<div role="alert"
     class="mt-3 alert alert-danger"
     *ngIf="!!error">{{error}}</div>
<div role="alert"
     class="mt-3 alert alert-info"
     *ngIf="!!result">{{result}}</div>
